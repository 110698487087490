import React, { useState } from 'react';
import './App.css';
import { storage } from './firebase';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { analytics } from './firebase'; // Import analytics
import { logEvent } from 'firebase/analytics';

function App() {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);


  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length === 0) return;
  
    setUploading(true);
    setUploadProgress(0);
    setUploadComplete(false); // Reset the upload completion state
  
    let totalUploaded = 0; // Keep track of the number of uploaded files
  
    Array.from(files).forEach((file) => {
      const storageRef = ref(storage, `uploads/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prevProgress) => {
            // Calculate the overall progress
            const increase = progress / files.length;
            return Math.min(prevProgress + increase - (prevProgress / files.length), 100);
          });
        },
        (error) => {
          console.error('Error uploading file:', error);
          setUploading(false);
          logEvent(analytics, 'upload_interrupted', { fileName: file.name }); // Log interrupted upload
        },
        () => {
          totalUploaded++;
          if (totalUploaded === files.length) {
            // All files are uploaded
            setUploadComplete(true);
            setUploading(false);
            setUploadProgress(100);
            logEvent(analytics, 'upload_successful', { fileCount: files.length }); // Log successful upload
          }
        }
      );
    });
  };
  
  

  return (
    <div className="App">
      <div className="upload-wrapper">
        <label htmlFor="file-input" className="custom-file-upload">
          {uploading ? "Uploading..." : "Share with us your pictures and videos!"}
        </label>
        <input
          id="file-input"
          type="file"
          multiple // Allow multiple file selection
          onChange={handleFileUpload}
          disabled={uploading}
        />
        {uploading && (
          <div className="progress-bar">
            <div className="progress" style={{ width: `${uploadProgress}%` }}>
              {Math.round(uploadProgress)}%
            </div>
          </div>
        )}
      </div>
      {uploadComplete && (
        <div className="upload-complete-message">
          Received your files ✅ <br />
          Upload more when you can. We love it!
        </div>
      )}
    </div>
  );
}

export default App;
