// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDdi2inwhAo6jQ4WBCJTxnqU347I8Xiebo",
  authDomain: "mofeedjude.firebaseapp.com",
  projectId: "mofeedjude",
  storageBucket: "mofeedjude.appspot.com",
  messagingSenderId: "301647380195",
  appId: "1:301647380195:web:7cc228ff788300d1088fd6",
  measurementId: "G-CGHEJ2688L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { storage, analytics };